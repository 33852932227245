export default [
  {
    route: 'dashboard',
    title: 'dashboard.title',
    icon: 'BarChart2Icon',
    roles: ['ROLE_USER'],
  },
  {
    route: 'dashboard-finance',
    title: 'dashboard.finance.title',
    icon: 'DollarSignIcon',
    // tag: 'shared.new',
    // tagVariant: 'light-success',
    roles: ['ROLE_FINANCE_MANAGER'],
  },
  {
    route: 'dashboard-map',
    title: 'dashboard.map.title',
    icon: 'MapIcon',
    // tag: 'shared.new',
    // tagVariant: 'light-success',
    roles: ['ROLE_DISPATCHER'],
  },
  {
    route: 'report',
    title: 'report.title',
    icon: 'FileTextIcon',
    // tag: 'shared.new',
    tagVariant: 'light-success',
    roles: ['ROLE_DATA_ANALYST'],
  },
  {
    title: 'schedule.title',
    icon: 'CalendarIcon',
    // roles: ['ROLE_USER'],
    roles: ['ROLE_SUPER_ADMIN'],
    children: [
      {
        route: 'schedule-my-availability',
        title: 'schedule.my-availability.title',
        roles: ['ROLE_USER'],
      },
      {
        route: 'schedule-planner',
        title: 'schedule.planner.title',
        roles: ['ROLE_HR_MANAGER'],
      },
    ],
  },
  {
    header: 'order.orders_title',
    roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
  },
  {
    route: 'order-list',
    title: 'order.list.title',
    icon: 'BookOpenIcon',
    roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
  },
  {
    route: 'address-list',
    title: 'address.menu.title',
    // tag: 'shared.new',
    tagVariant: 'light-success',
    icon: 'MapPinIcon',
    roles: ['ROLE_DISPATCHER'],
  },
  {
    route: 'customer-list',
    title: 'customer.menu.title',
    // tag: 'shared.new',
    tagVariant: 'light-success',
    icon: 'BookmarkIcon',
    roles: ['ROLE_FINANCE_MANAGER'],
  },
  {
    header: 'staff.staff_title',
    roles: ['ROLE_HR_MANAGER', 'ROLE_USER_MANAGEMENT', 'ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
  },
  {
    route: 'team-list',
    title: 'team.list.title',
    // tag: 'shared.new',
    tagVariant: 'light-success',
    icon: 'UsersIcon',
    roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
  },
  {
    route: 'user-list',
    title: 'conf.user.title',
    icon: 'UserIcon',
    roles: ['ROLE_USER_MANAGEMENT'],
  },
  {
    header: 'conf.title',
    roles: ['ROLE_ADMIN', 'ROLE_DISPATCHER'],
  },
  {
    route: 'vehicle-list',
    title: 'conf.vehicle.title',
    icon: 'TruckIcon',
    roles: ['ROLE_DISPATCHER', 'ROLE_CONFIG_MANAGEMENT'],
  },
  {
    title: 'conf.settings.title',
    icon: 'SettingsIcon',
    roles: ['ROLE_CONFIG_MANAGEMENT'],
    children: [
      {
        route: 'settings-team-name-list',
        title: 'conf.settings.team-names.title',
        roles: ['ROLE_CONFIG_MANAGEMENT'],
      },
      {
        route: 'settings-checklist-dictionary-list',
        title: 'conf.settings.checklist-dictionary.title',
        roles: ['ROLE_CONFIG_MANAGEMENT'],
      },
    ],
  },
]
